const changeMainImage = function changeMainImage(newImageDiv) {
    const mainImageLink = window['$']('a.ui.fluid.image');
    const mainImage = window['$']('a.ui.fluid.image > img');

    const newImage = window['$'](newImageDiv).find('img');
    const newImageLink = window['$'](newImageDiv).find('a');

    if (newImage.length === 0 && newImageLink.length === 0) {
        mainImage.attr('src', window['$']('div[data-product-image]').attr('data-product-image'));
        newImageLink.attr('href', window['$']('div[data-product-link]').attr('data-product-link'));

        return;
    }

    mainImageLink.attr('href', newImageLink.attr('href'));
    mainImage.attr('src', newImage.attr('data-large-thumbnail'));
};

const handleProductOptionImages = function handleProductOptionImages() {
    let options = '';

    window['$']('#sylius-product-adding-to-cart select').each((index, select) => {
        options += `${window['$'](select).find('option:selected').val()} `;
    });

    const imagesWithOptions = [];
    const optionsArray = options.trim().split(' ');

    window['$']('[data-variant-options]').each((index, element) => {
        const imageOptions = window['$'](element).attr('data-variant-options');
        const imageHasOptions = optionsArray.every(option => imageOptions.indexOf(option) > -1);

        if (imageHasOptions) {
            imagesWithOptions.push(window['$'](element).closest('div.ui.image'));
        }
    });

    changeMainImage(imagesWithOptions.shift());
};

const handleProductOptionChange = function handleProductOptionChange() {
    window['$']('[name*="sylius_add_to_cart[cartItem][variant]"]').on('change', () => {
        handleProductOptionImages();
    });
};

const handleProductVariantImages = function handleProductVariantImages(variantElement) {
    const variantCode = window['$'](variantElement).attr('value');
    const imagesWithVariantCode = [];

    window['$'](`[data-variant-code*="${variantCode}"]`).each((index, element) => {
        imagesWithVariantCode.push(window['$'](element).closest('div.ui.image'));
    });

    changeMainImage(imagesWithVariantCode.shift());
};

const handleProductVariantChange = function handleProductVariantChange() {
    window['$']('[name="sylius_add_to_cart[cartItem][variant]"]').on('change', (event) => {
        handleProductVariantImages(window['$'](event.currentTarget));
    });
};

window['$'].fn.extend({
    variantImages() {
        if (window['$']('[data-variant-options]').length > 0) {
            handleProductOptionImages();
            handleProductOptionChange();
        } else if (window['$']('[data-variant-code]').length > 0) {
            handleProductVariantImages(window['$']('[name="sylius_add_to_cart[cartItem][variant]"]'));
            handleProductVariantChange();
        }
    },
});
