window['$'].fn.extend({
    checkAll() {
        this.each((idx, el) => {
            const $checkboxAll = window['$'](el);
            const $checkboxes = window['$']($checkboxAll.attr('data-js-bulk-checkboxes'));
            const $buttons = window['$']($checkboxAll.attr('data-js-bulk-buttons'));

            const isAnyChecked = () => {
                let checked = false;
                $checkboxes.each((i, checkbox: HTMLInputElement) => {
                    if (checkbox.checked)
                        checked = true;
                });
                return checked;
            };

            const buttonsPropRefresh = () => {
                $buttons.find('button').prop('disabled', !isAnyChecked());
            };

            $checkboxAll.on('change', () => {
                $checkboxes.prop('checked', window['$'](this).is(':checked'));
                buttonsPropRefresh();
            });

            $checkboxes.on('change', () => {
                $checkboxAll.prop('checked', isAnyChecked());
                buttonsPropRefresh();
            });

            buttonsPropRefresh();
        });
    },
});
