export default (formSelector: string = 'form#warranty-form') => {
    // Check if the form exists
    let form = document.querySelector(formSelector) as HTMLFormElement | null

    if (form === null)
        return

    let errorCommentMessage = document.querySelector('#leaveCommentError')

    form.addEventListener('submit', (event) => {
        errorCommentMessage.classList.add('d-none')

        if (document.querySelector("input[name='warranty_form[leaveComment]']:checked") === null) {
            event.preventDefault()
            errorCommentMessage.classList.remove('d-none')
        }
    })
}
