const handleProductOptionsChange = function handleProductOptionsChange() {
    window['$']('[name*="sylius_add_to_cart[cartItem][variant]"]').on('change', () => {
        let selector = '';

        window['$']('#sylius-product-adding-to-cart select[data-option]').each((index, element) => {
            const select = window['$'](element);
            const option = select.find('option:selected').val();
            selector += `[data-${select.attr('data-option')}="${option}"]`;
        });

        const price = window['$']('#sylius-variants-pricing').find(selector).attr('data-value');
        const originalPrice = window['$']('#sylius-variants-pricing').find(selector).attr('data-original-price');

        if (price !== undefined) {
            window['$']('#product-price').text(price);
            window['$']('button[type=submit]').removeAttr('disabled');

            if (originalPrice !== undefined) {
                window['$']('#product-original-price').css('display', 'inline').html(`<del>${originalPrice}</del>`);
            } else {
                window['$']('#product-original-price').css('display', 'none');
            }
        } else {
            window['$']('#product-price').text(window['$']('#sylius-variants-pricing').attr('data-unavailable-text'));
            window['$']('button[type=submit]').attr('disabled', 'disabled');
        }
    });
};

const handleProductVariantsChange = function handleProductVariantsChange() {
    window['$']('[name="sylius_add_to_cart[cartItem][variant]"]').on('change', (event) => {
        const priceRow = window['$'](event.currentTarget).parents('tr').find('.sylius-product-variant-price');
        const price = priceRow.text();
        const originalPrice = priceRow.attr('data-original-price');
        window['$']('#product-price').text(price);

        if (originalPrice !== undefined) {
            window['$']('#product-original-price').css('display', 'inline').html(`<del>${originalPrice}</del>`);
        } else {
            window['$']('#product-original-price').css('display', 'none');
        }
    });
};

window['$'].fn.extend({
    variantPrices() {
        if (window['$']('#sylius-variants-pricing').length > 0) {
            handleProductOptionsChange();
        } else if (window['$']('#sylius-product-variants').length > 0) {
            handleProductVariantsChange();
        }
    },
});
