import 'fomantic-ui/dist/components/api.js';
import 'fomantic-ui/dist/components/popup.js';
import 'fomantic-ui/dist/components/rating.js'
import 'fomantic-ui/dist/components/accordion.js';
import 'fomantic-ui/dist/components/checkbox.js';
import 'fomantic-ui/dist/components/dimmer.js';
import 'fomantic-ui/dist/components/dropdown.js';
import 'fomantic-ui/dist/components/rating.js';
import 'fomantic-ui/dist/components/sidebar.js';
import 'fomantic-ui/dist/components/tab.js';
import 'fomantic-ui/dist/components/transition.js';
import 'fomantic-ui/dist/components/modal.js';

import './ui'
import './shop'
