window['$'].fn.extend({
    apiToggle({
                  method,
                  dataType = 'json',
                  throttle = 0,
                  debug = false,
                  beforeSend,
                  successTest,
              }, toggleableElement, isHidden = true) {
        const element = this;

        if (isHidden) {
            toggleableElement.hide();
        }

        element.api({
            method,
            dataType,
            throttle,
            debug,

            beforeSend,
            successTest,

            onSuccess() {
                toggleableElement.show();
            },

            onFailure() {
                toggleableElement.hide();
            },
        });
    },
});
