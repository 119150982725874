/*
 * This file is part of the Sylius package.
 *
 * (c) Paweł Jędrzejewski
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

window['$'].fn.extend({
  bulkActionRequireConfirmation() {
    this.each((idx, el) => {
      window['$'](el).on('click', (evt) => {
        evt.preventDefault();

        const actionButton = window['$'](evt.currentTarget);

        if (actionButton.is('a')) {
          window['$']('#confirmation-button').attr('href', actionButton.attr('href'));
        }

        if (actionButton.is('button')) {
          window['$']('#confirmation-button').on('click', (event) => {
            event.preventDefault();

            const form = actionButton.closest('form');
            window['$']('input.bulk-select-checkbox:checked').each((index, element) => {
              window['$'](`<input type="hidden" name="ids[]" value="${element.value}">`).appendTo(form);
            });

            form.submit();
          });
        }

        window['$']('#confirmation-modal').modal('show');
      });
    });
  },
});
