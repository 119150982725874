import ready from './helpers/ready'

ready(function () {
    $(".video").each(function( index ) {
        let $videoUrl = $('.videolink', this).attr('href')
        let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/
        let match = $videoUrl.match(regExp)
        let $youtubeVideoUrl = (match&&match[7].length==11)? match[7] : false
        let $youtubeConcat = 'https://img.youtube.com/vi/'+ $youtubeVideoUrl + '/hqdefault.jpg'

        $( '.videolink img', this ).attr('src', $youtubeConcat)
    })
})
