window['$'].fn.extend({
    removeFromCart: function() {
        this.each(function (index, element) {
            let redirectUrl = window['$'](element).data('redirect');
            let csrfToken = window['$'](element).data('csrf-token');

            window['$'](element).api({
                method: 'DELETE',
                on: 'click',
                beforeSend: (settings) => {
                    /* eslint-disable-next-line no-param-reassign */
                    settings.data = {
                        _csrf_token: csrfToken
                    };

                    return settings;
                },
                onSuccess: () => {
                    window.location.replace(redirectUrl);
                }
            });
        });
    }
});
