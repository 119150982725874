const getProvinceInputValue = function getProvinceInputValue(valueSelector) {
    return valueSelector == undefined ? '' : `value="${valueSelector}"`;
};

window['$'].fn.extend({
    provinceField() {
        const countrySelect = window['$']('select[name$="[countryCode]"]');

        countrySelect.on('change', (event) => {
            const select = window['$'](event.currentTarget);
            const provinceContainer = select.parents('.field').next('div.province-container');

            const provinceSelectFieldName = select.attr('name').replace('country', 'province');
            const provinceInputFieldName = select.attr('name').replace('countryCode', 'provinceName');

            const provinceSelectFieldId = select.attr('id').replace('country', 'province');
            const provinceInputFieldId = select.attr('id').replace('countryCode', 'provinceName');

            const form = select.parents('form');

            if (select.val() === '' || select.val() == undefined) {
                provinceContainer.fadeOut('slow', () => {
                    provinceContainer.html('');
                });

                return;
            }

            provinceContainer.attr('data-loading', true);
            form.addClass('loading');

            window['$'].get(provinceContainer.attr('data-url'), { countryCode: select.val() }, (response) => {
                if (!response.content) {
                    provinceContainer.fadeOut('slow', () => {
                        provinceContainer.html('');

                        provinceContainer.removeAttr('data-loading');
                        form.removeClass('loading');
                    });
                } else if (response.content.indexOf('select') !== -1) {
                    provinceContainer.fadeOut('slow', () => {
                        const provinceSelectValue = getProvinceInputValue((
                            window['$'](provinceContainer).find('select > option[selected$="selected"]').val()
                        ));

                        provinceContainer.html((
                            response.content
                                .replace('name="sylius_address_province"', `name="${provinceSelectFieldName}"${provinceSelectValue}`)
                                .replace('id="sylius_address_province"', `id="${provinceSelectFieldId}"`)
                                .replace('option value="" selected="selected"', 'option value=""')
                                .replace(`option ${provinceSelectValue}`, `option ${provinceSelectValue}" selected="selected"`)
                        ));

                        provinceContainer.removeAttr('data-loading');

                        provinceContainer.fadeIn('fast', () => {
                            form.removeClass('loading');
                        });
                    });
                } else {
                    provinceContainer.fadeOut('slow', () => {
                        const provinceInputValue = getProvinceInputValue(window['$'](provinceContainer).find('input').val());

                        provinceContainer.html((
                            response.content
                                .replace('name="sylius_address_province"', `name="${provinceInputFieldName}"${provinceInputValue}`)
                                .replace('id="sylius_address_province"', `id="${provinceInputFieldId}"`)
                        ));

                        provinceContainer.removeAttr('data-loading');

                        provinceContainer.fadeIn('fast', () => {
                            form.removeClass('loading');
                        });
                    });
                }
            });
        });

        if (countrySelect.val() !== '') {
            countrySelect.trigger('change');
        }

        if (window['$'].trim(window['$']('div.province-container').text()) === '') {
            window['$']('select.country-select').trigger('change');
        }

        const shippingAddressCheckbox = window['$']('input[type="checkbox"][name$="[differentShippingAddress]"]');
        const shippingAddressContainer = window['$']('#sylius-shipping-address-container');
        const toggleShippingAddress = function toggleShippingAddress() {
            shippingAddressContainer.toggle(shippingAddressCheckbox.prop('checked'));
        };
        toggleShippingAddress();
        shippingAddressCheckbox.on('change', toggleShippingAddress);
    },
});
