window["$"].fn.extend({
  addToCart: () => {
    const element = this;
    if (element.length > 0) {
      const url = window["$"](element).attr("action");
      const redirectUrl = window["$"](element).data("redirect");
      const validationElement = window["$"]("#sylius-cart-validation-error");

      element.api({
        method: "POST",
        on: "submit",
        cache: false,
        url,
        beforeSend(settings) {
          /* eslint-disable-next-line no-param-reassign */
          settings.data = element.serialize();

          return settings;
        },
        onSuccess() {
          validationElement.addClass("hidden");
          window.location.href = redirectUrl;
        },
        onFailure(response) {
          validationElement.removeClass("hidden");
          let validationMessage = "";

          Object.entries(response.errors.errors).forEach(([, message]) => {
            validationMessage += message;
          });
          validationElement.html(validationMessage);
          window["$"](element).removeClass("loading");
        },
      });
    }
  },
});
