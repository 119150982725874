window['$'].fn.extend({
    toggleElement() {
        this.each((idx, el) => {
            window['$'](el).on('change', (event) => {
                event.preventDefault();

                const toggle = window['$'](event.currentTarget);
                const targetElement = window['$'](`#${toggle.data('toggles')}`);

                if (toggle.is(':checked')) {
                    targetElement.show();
                } else {
                    targetElement.hide();
                }
            });

            window['$'](el).trigger('change');
        });
    },
});
