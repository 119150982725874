window['$'].fn.extend({
    requireConfirmation() {
        this.each((idx, el) => {
            window['$'](el).on('click', (evt) => {
                evt.preventDefault();

                const actionButton = window['$'](evt.currentTarget);

                if (actionButton.is('a')) {
                    window['$']('#confirmation-button').attr('href', actionButton.attr('href'));
                }

                if (actionButton.is('button')) {
                    window['$']('#confirmation-button').on('click', (event) => {
                        event.preventDefault();

                        actionButton.closest('form').submit();
                    });
                }

                window['$']('#confirmation-modal').modal('show');
            });
        });
    },
});
