try {
    window['Popper'] = require('popper.js').default;
    window['$'] = window['jQuery'] = require('jquery');

    require('bootstrap');
    window['bsCustomFileInput'] = require('bs-custom-file-input');

    window['$'].fn.bsModal = window['$'].fn.modal.noConflict()

    require('slick-carousel')
} catch (e) {}

window['lity'] = require('lity')
window['lightbox'] = require('lightbox2')
