import ready from '../helpers/ready'

// UI Bundle
// ------------------------------------------------
import './ui/sylius-api-login';
import './ui/sylius-api-toggle';
import './ui/sylius-bulk-action-require-confirmation';
import './ui/sylius-form-collection';
import './ui/sylius-require-confirmation';
import './ui/sylius-toggle';
import './ui/sylius-check-all';

ready(() => {
    window['$']('#sidebar').addClass('visible');
    window['$']('#sidebar').sidebar('attach events', '#sidebar-toggle', 'toggle');
    window['$']('#sidebar').sidebar('setting', {
        dimPage: false,
        closable: false,
    });

    window['$']('.ui.checkbox').checkbox();
    window['$']('.ui.accordion').accordion();
    window['$']('.ui.menu .dropdown').dropdown({ action: 'hide' });
    window['$']('.ui.inline.dropdown').dropdown();
    window['$']('.link.ui.dropdown').dropdown({ action: 'hide' });
    window['$']('.button.ui.dropdown').dropdown({ action: 'hide' });
    window['$']('.ui.fluid.search.selection.ui.dropdown').dropdown();
    window['$']('.ui.tabular.menu .item, .sylius-tabular-form .menu .item').tab();
    window['$']('.ui.card .dimmable.image, .ui.cards .card .dimmable.image').dimmer({ on: 'hover' });
    window['$']('.ui.rating').rating('disable');

    window['$']('form.loadable button[type=submit]').on('click', (event) => {
        window['$'](event.currentTarget).closest('form').addClass('loading');
    });
    window['$']('.loadable.button').on('click', (event) => {
        window['$'](event.currentTarget).addClass('loading');
    });
    window['$']('.message .close').on('click', (event) => {
        window['$'](event.currentTarget).closest('.message').transition('fade');
    });

    window['$']('[data-requires-confirmation]').requireConfirmation();
    window['$']('[data-bulk-action-requires-confirmation]').bulkActionRequireConfirmation();
    window['$']('[data-toggles]').toggleElement();
    window['$']('[data-js-bulk-checkboxes]').checkAll();

    window['$']('.special.cards .image').dimmer({
        on: 'hover',
    });

    window['$']('[data-form-type="collection"]').CollectionForm();

    window['$']('[data-js-disable]').on('click', (e) => {
        const $current = window['$'](e.currentTarget);
        window['$'](document).find($current.attr('data-js-disable')).addClass('disabled');
    });
});
