import ready from '../helpers/ready'

// Shop Bundle
// ------------------------------------------------
import './shop/sylius-add-to-cart';
import './shop/sylius-remove-from-cart';
import './shop/sylius-address-book';
import './shop/sylius-province-field';
import './shop/sylius-variant-images';
import './shop/sylius-variants-prices';

ready(() => {
    window['$']('.popup-js').popup();

    window['$']('.cart.button')
        .popup({
            popup: window['$']('.cart.popup'),
            on: 'click',
        });

    window['$']('.star.rating').rating({
        fireOnInit: true,
        onRate(value) {
            window['$']('[name="sylius_product_review[rating]"]:checked').removeAttr('checked');
            window['$'](`#sylius_product_review_rating_${value - 1}`).attr('checked', 'checked');
        },
    });

    window['$']('#sylius_checkout_address_customer_email').apiToggle({
        dataType: 'json',
        method: 'GET',
        throttle: 1500,

        beforeSend(settings) {
            const email = window['$']('#sylius_checkout_address_customer_email').val();

            if (email.length < 3) {
                return false;
            }

            /* eslint-disable-next-line no-param-reassign */
            settings.data = {
                email,
            };

            return settings;
        },

        successTest(response) {
            return window['$']('#sylius_checkout_address_customer_email').val() === response.username;
        },
    }, window['$']('#sylius-api-login-form'));

    window['$']('#sylius-api-login').apiLogin({
        method: 'POST',
        throttle: 500,
    });

    window['$']('#sylius-product-adding-to-cart').addToCart();
    window['$']('.sylius-cart-remove-button').removeFromCart();

    window['$']('#sylius-shipping-address').addressBook();
    window['$']('#sylius-billing-address').addressBook();
    window['$'](document).provinceField();
    window['$'](document).variantPrices();
    window['$'](document).variantImages();

    window['$']('body').find('input[autocomplete="off"]').prop('autocomplete', 'disable');

    window['$']('.carousel').slick({
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        prevArrow: window['$']('.carousel-left'),
        nextArrow: window['$']('.carousel-right'),
        appendArrows: false
    });
});
