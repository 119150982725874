import * as $ from 'jquery'
import ready from './helpers/ready'

ready(() => {
    let currentJob = $('h1').html()
    $('#currentJob').html(currentJob)
    $('#currentJobInput').val(currentJob)
    $('.jobs').each(() => {
        if ($(this).html() === currentJob) {
            $(this).parent().remove()
        }
    })
})

